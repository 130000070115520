import Image from "next/image";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";

import IMAGES from "../configs/images";
import PATHS from "../configs/paths";

import styles from "../styles/news.module.scss";

export default function News({ item }) {
  const router = useRouter();
  const { isLightMode } = useSelector((state) => state.appSlices);

  const isoCode = router.locale || "en";

  const onClick = () => {
    router.push({
      pathname: PATHS.NEWS + "/" + item.id,
    });
  };
  const imageLoader = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality || 75}`;
  };

  return (
    <div onClick={onClick} className={styles["news"]}>
      <div className={styles["bg-news"]}>
        <Image
          src={item?.image || IMAGES.LOGO.src}
          loading="lazy"
          alt="news"
          quality={100}
          fill
          sizes="(min-width: 300px) 50vw, 100vw"
          style={{
            objectFit: "cover",
          }}
          loader={imageLoader}
          onError={(e) => e.target.setAttribute("src", IMAGES.LOGO.src)}
        />
      </div>

      <div className={styles["desc"]}>
        <div className={styles["title"]}>
          <Image alt="news" src={IMAGES.NEWS} width={20} height={20} />
          {item.news_translations.map((el, index) => {
            return (
              <p key={index} style={{ color: isLightMode ? "#000" : "#fff" }}>
                {el.iso_code == isoCode ? el.title : el.title}
              </p>
            );
          })}
        </div>

        <Image
          src={isLightMode ? IMAGES.NEWS_LINE_LIGHT.src : IMAGES.NEWS_LINE.src}
          loading="lazy"
          alt="news"
          width={330}
          height={33}
        />

        {item.news_translations.map((el, index) => {
          return (
            <p
              className={`${styles["description"]}`}
              style={{ color: isLightMode ? "#000" : "#fff" }}
              key={index}
            >
              {el.iso_code == isoCode ? el.description : el.description}
            </p>
          );
        })}
      </div>
    </div>
  );
}
